import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.abccheck.webarysites.com/api",
  headers: {
    "Content-Type": "application/json"
  }
});

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
