import React, { useState, useEffect } from "react";

import { Edit2, Plus, Trash } from "react-feather";
import { Card, Col, Row, Table, Button } from "react-bootstrap";
import WithLoading from "../../components/WithLoading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProductVariants, getTemplates } from "../../services/productVariant";

const ProductVariants = ({ isTemplate = false }) => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [productVariants, setProductVariants] = useState([]);
  const [loading, setLoading] = useState(true);

  const createProductVariant = () => {
    navigate(`/product-${isTemplate ? "template" : "variant"}`);
  };

  const editProductVariant = (variant) => {
    navigate(`/product-${isTemplate ? "template" : "variant"}?variantId=${variant.id}`, {
      state: variant,
    });
  };

  const deleteProductVariant = async (id) => {
    if (confirm("Are you sure?")) {
      await fetch(`https://api.abccheck.webarysites.com/api/ProductVarient/delete/${id}`, { method: 'post' });
      window.location.reload();
    }
  }

  useEffect(() => {
    const wpProductId = query.get("WPProductId");
    (isTemplate ? getTemplates() : getProductVariants(wpProductId))
      .then(x => setProductVariants(x.filter(x => !x.deleted)))
      .finally(() => setLoading(false));
  }, [isTemplate]);

  return (
    <React.Fragment>
      <WithLoading loading={loading} fluid className="p-0">
        <Row>
          <Col>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <h3>Product {isTemplate ? "Templates" : "Variants"}</h3>
                <Button onClick={createProductVariant}>
                  <Plus size={20} className="me-1" /> Create product {isTemplate ? "template" : "variant"}
                </Button>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productVariants.map((prod, index) => (
                          <tr key={index}>
                            <td>{!prod.name || prod.name == 'null' ? "N/A" : prod.name}</td>
                            <td>{!prod.description || prod.description == 'null' ? "N/A" : prod.description}</td>
                            <td className="table-action">
                              <Edit2
                                className="align-middle me-1"
                                size={18}
                                onClick={() => editProductVariant(prod)}
                              />
                              <Trash
                                className="align-middle"
                                size={18}
                                onClick={() => deleteProductVariant(prod.id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </WithLoading>
    </React.Fragment>
  );
};

export default ProductVariants;
