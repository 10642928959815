import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";

import NotyfContext from "../../contexts/NotyfContext";

const CreateBackground = ({ afterCreate }) => {
  const notyf = useContext(NotyfContext);
  const formData = new FormData();

  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  const submit = async () => {
    //let formData = new FormData();
    formData.append("name", name);

    let res = await fetch(
      "https://api.abccheck.webarysites.com/api/Backgrounds",
      {
        method: "POST",
        body: formData,
      }
    );

    notyf.open({
      type: "success",
      message: "Background saved successfully",
      position: {
        x: "right",
        y: "top",
      },
    });
    afterCreate();

    setName("");
  };

  const fileHandler = (e) => {
    //console.log(e);
    let file = e.target.files[0];
    //console.log(file);
    // setSelectedFile(file);

    formData.delete("file");
    formData.append("file", e.target.files[0]);
  };
  return (
    <>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>File</Form.Label>
          <Form.Control
            type="file"
            value={selectedFile}
            onChange={fileHandler}
          />
        </Form.Group>

        <Button onClick={submit} className="float-end">
          Save
        </Button>
      </Form>
    </>
  );
};

export default CreateBackground;
