import { fabric } from "fabric";

/**
 * @param {string} srcBase64
 * @param {fabric.Canvas} canvas
 */
export const addImage = (srcBase64, canvas) => {
  fabric.Image.fromURL(
    srcBase64,
    function (img) {
      img.set({
        left: 15,
        top: 15,
      });
      // as an option
      let height = 56;
      if (img.width / img.height > 5) {
        height = 32;
      } else if (img.width / img.height > 4) {
        height = 36;
      } else if (img.width / img.height > 3) {
        height = 40;
      } else if (img.width / img.height > 2) {
        height = 48;
      }

      img.scaleToHeight(height);
      img.scaleToWidth(height * (img.width / img.height));
      img.fill = "canvas-image";
      img.visible = true;

      canvas.add(img).setActiveObject(img).renderAll();
    },
    {
      crossOrigin: "anonymous"
    }
  );
};
