import React from "react";
import { Container, Spinner } from "react-bootstrap";

const WithLoading = ({ loading, Component = Container, ...rest }) => (
  <>
    {loading ? (
      <Container className="h-100 d-flex align-items-center justify-content-center">
        <Spinner animation="border" />
      </Container>
    ) : (
      <Component {...rest} />
    )}
  </>
);

export default WithLoading;
