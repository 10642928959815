import { useEffect, useState } from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { Edit2, Plus, Trash } from "react-feather";
import CreateBackground from "../../components/check/CreateBackground";
import WithLoading from "../../components/WithLoading";

const Backgrounds = () => {
  const [backgrounds, setBackgrounds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(async () => {
    let response = await fetch(
      "https://api.abccheck.webarysites.com/api/Backgrounds",
      {
        method: "GET",
      }
    );

    if (response.ok) {
      let json = await response.json();
      setBackgrounds(json);
    }
    setLoading(false);
  }, []);

  const deleteBackground = async (id) => {
    if (confirm("Are you sure?")) {
      let response = await fetch(
        `https://api.abccheck.webarysites.com/api/Backgrounds/Delete/${id}`,
        {
          method: "POST",
        }
      );

      if (response.ok) {
        //let json = await response.json();
        setBackgrounds(backgrounds.filter((x) => x.id != id));
        // console.log(backgrounds);
      }
    }
  };

  return (
    <WithLoading Component={Card} loading={loading}>
      <Card.Header className="d-flex justify-content-between">
        <h3>Backgrounds</h3>
        <Button onClick={() => setShow(true)}>
          <Plus size={20} className="me-1" /> Add
        </Button>
        <Modal show={show} onHide={() => setShow(false)} size="sm">
          <Modal.Header closeButton>Add Background</Modal.Header>
          <Modal.Body>
            <CreateBackground afterCreate={() => setShow(false)} />
          </Modal.Body>
        </Modal>
      </Card.Header>
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>File path</th>
            </tr>
          </thead>
          <tbody>
            {backgrounds.map((background, index) => (
              <tr key={index}>
                <td>{background.name}</td>
                <td>{background.filePath}</td>
                <td className="table-action">
                  <Edit2
                    className="align-middle me-1"
                    size={18}
                    onClick={() => editProduct(background.id)}
                  />
                  <Trash
                    className="align-middle"
                    size={18}
                    onClick={() => deleteBackground(background.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </WithLoading>
  );
};

export default Backgrounds;
