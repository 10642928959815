import { Image, Layers } from "react-feather";

const pagesSection = [
  {
    href: "/product-variants",
    icon: Layers,
    title: "Product Variants",
  },
  {
    href: "/backgrounds",
    icon: Image,
    title: "Backgrounds",
  },
  {
    href: "/product-templates",
    icon: Layers,
    title: "Product Templates",
  },
];

const navItems = [
  {
    title: "Admin Section",
    pages: pagesSection,
  },
];

export default navItems;
