import React from "react";
import { Navigate } from "react-router-dom";

// Layouts
import DashboardLayout from "./layouts/Dashboard";

// Protected routes
import ProductVariants from "./pages/checks/ProductVariants";
import ProductVariant from "./pages/checks/ProductVariant";
import Backgrounds from "./pages/checks/Backgrounds";

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "product-variants",
        element: <ProductVariants />,
      },
      {
        path: "backgrounds",
        element: <Backgrounds />,
      },
      {
        path: "product-variant",
        element: <ProductVariant />,
      },
      {
        path: "product-templates",
        element: <ProductVariants isTemplate />,
      },
      {
        path: "product-template",
        element: <ProductVariant isTemplate />,
      },
      {
        path: "",
        element: <Navigate to="product-variants" />,
      },
    ],
  },
  // {
  //   path: "/",
  //   element: <LandingLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Landing />,
  //     },
  //   ],
  // },
  // {
  //   path: '/',
  //   element: <LandingLayout />,
  //   children: [
  //     { path: '', element: <Checks /> }
  //   ]
  // },
  // {
  //   path: "private",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <ProtectedPage />,
  //     },
  //   ],
  // },
  // {
  //   path: "*",
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "*",
  //       element: <Page404 />,
  //     },
  //   ],
  // },
];

export default routes;
