import axios from "../utils/axios";

export function getProductVariants(wpProductId) {
    return axios.get(wpProductId
        ? `/ProductVarient/GetProductDetailsByWPProductId/${wpProductId}`
        : "/ProductVarient/1"
    );
}

export function getTemplates() {
    return axios.get("/TemplateVariant/GetTemplates");
}
