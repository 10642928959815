const annotations = [
    {
        label: "Company Name",
        isRequired: true,
        isBold: false,
        id: "companyName",
    },
    {
        label: "Company Address",
        isRequired: true,
        isBold: false,
        id: "companyAddress",
    },
    {
        label: "Company Address 2",
        isRequired: false,
        isBold: false,
        id: "companyAddress2",
    },
    {
        label: "City State Zip",
        isRequired: true,
        isBold: false,
        id: "cityStateZip",
    },
    {
        label: "Phone number",
        isRequired: false,
        isBold: false,
        id: "phoneNumber",
    },
    {
        label: "Additional information",
        isRequired: false,
        isBold: false,
        id: "additionalInformation",
    },
    {
        label: "Bank name",
        isRequired: true,
        isBold: false,
        id: "bankName",
    },
    {
        label: "Bank address",
        isRequired: true,
        isBold: false,
        id: "bankAddress",
    },
    {
        label: "Bank City State Zip",
        isRequired: true,
        isBold: false,
        id: "bankCityStateZip",
    },
    {
        label: "9 digit routing number",
        isRequired: true,
        id: "nineDigitRoutingNumner",
    },
    {
        label: "Account number",
        isRequired: true,
        id: "accountNumber",
    },
    {
        label: "Routing fraction number",
        isRequired: false,
        id: "routingFractionNumber",
    },
    {
        label: "Check starting number",
        isRequired: false,
        id: "checkStartingNumber",
    },
    {
        label: "Check starting number bottom",
        isRequired: false,
        id: "checkStartingNumberBottom",
    },
    {
        label: "Text above signature line",
        isRequired: false,
        id: "textAboveSignatureLine",
    },
];

export default annotations;
