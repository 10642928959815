import React from "react";
import { Modal } from "react-bootstrap";

/**
 * @param {{
 *  show: boolean;
 *  onClose: () => void;
 * } & React.ImgHTMLAttributes<HTMLImageElement>} props
 */
const ImagePreview = ({ show, onClose, ...imgProps }) => (
  <Modal show={show} onHide={onClose} size="lg">
    <Modal.Header closeButton />
    <Modal.Body className="text-center m-3">
      <img {...imgProps} />
    </Modal.Body>
  </Modal>
);

export default ImagePreview;
